import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    setScrolled(window.scrollY > 80);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleResize = () => {
    if (window.innerWidth > 1024) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 shadow-none ${scrolled ? "bg-white" : "bg-transparent"}`}
    >
      <div className="container mx-auto flex justify-between items-center py-2 md:py-3 lg:py-4 rounded-md">
        <Link
          to="/"
          className={`text-2xl sm:text-3xl font-bold transition-colors duration-300 ${scrolled ? "text-gray-800" : "text-slate-600"} ml-2`}
        >
          CityShorts
        </Link>

        {location.pathname === "/shop" && (
          <div className="hidden sm:flex items-center mx-2 sm:mx-4">
            {/* Search bar only visible on screens larger than 'sm' */}
            <input
              type="text"
              placeholder="Wat heb je nodig?"
              className="border border-gray-300 bg-transparent rounded-l-lg px-1 py-1 focus:outline-none"
            />
            <button className="border border-gray-300 bg-transparent text-gray-500 rounded-r-lg px-5 py-1 hover:font-medium">
              Zoek
            </button>
          </div>
        )}

        <div className="hidden md:flex md:items-center md:ml-auto space-x-1">
          {location.pathname !== "/shop" && (
            <Link
              to="/shop"
              className={`text-base sm:text-lg font-semibold rounded-lg px-2 py-1 transition-all duration-300 ${scrolled ? "text-gray-800 hover:bg-gray-200" : "text-slate-600 hover:bg-blue-200"}`}
            >
              Shop
            </Link>
          )}
          <Link
            to="/login"
            className={`text-base sm:text-lg font-semibold rounded-lg px-2 py-1 transition-all duration-300 ${scrolled ? "text-gray-800 hover:bg-gray-200" : "text-slate-600 hover:bg-blue-200"}`}
          >
            Inloggen
          </Link>
          <Link
            to="/signup"
            className={`text-base sm:text-lg font-semibold rounded-lg px-2 py-1 transition-all duration-300 ${scrolled ? "text-gray-800 hover:bg-gray-200" : "text-slate-600 hover:bg-blue-200"}`}
          >
            Aanmelden
          </Link>
          <Link
            to="/about"
            className={`text-base sm:text-lg font-semibold rounded-lg px-2 py-1 transition-all duration-300 ${scrolled ? "text-gray-800 hover:bg-gray-200" : "text-slate-600 hover:bg-blue-200"}`}
          >
            Over ons
          </Link>
        </div>

        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className={`focus:outline-none transition duration-200 ${scrolled ? "text-gray-800 hover:bg-gray-200" : "text-slate-600 hover:bg-sky-200"} rounded-md p-2`}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>

      {menuOpen && (
        <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md w-48 z-50">
          <Link
            to="/shop"
            className="block px-4 py-2 text-gray-800 hover:bg-sky-200 rounded-t-md transition duration-200"
          >
            Shop
          </Link>
          <Link
            to="/login"
            className="block px-4 py-2 text-gray-800 hover:bg-sky-200 transition duration-200"
          >
            Inloggen
          </Link>
          <Link
            to="/signup"
            className="block px-4 py-2 text-gray-800 hover:bg-sky-200 transition duration-200"
          >
            Aanmelden
          </Link>
          <Link
            to="/about"
            className="block px-4 py-2 text-gray-800 hover:bg-sky-200 rounded-b-md transition duration-200"
          >
            Over ons
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
