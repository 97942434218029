import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/frontpage/Navbar";
import Footer from "../components/frontpage/Footer";
import ProductCard from "../components/shop/ProductCard"; // For related products

const ProductPage = ({ products }) => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  // Scroll to the top when the product page changes
  useEffect(() => {
    window.scrollTo(0, 0);  // This ensures the page scrolls to the top
    const foundProduct = products.find((item) => item.id === parseInt(id));
    setProduct(foundProduct);
  }, [id, products]);  // The effect runs whenever the product id changes

  if (!product) return <div>Loading...</div>;

  // Filter related products (could be based on category, tags, or other criteria)
  const relatedProducts = products.filter(
    (item) => item.id !== product.id && item.category === product.category
  );

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-8 mt-28">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Product Image Gallery */}
          <div className="space-y-4">
            <img
              src={product.image}
              alt={product.name}
              className="w-full h-64 object-cover mb-4"
            />
            {/* Add more product images here */}
            <div className="grid grid-cols-3 gap-4">
              <img src={product.image} alt={product.name} className="w-full h-20 object-cover" />
              <img src={product.image} alt={product.name} className="w-full h-20 object-cover" />
              <img src={product.image} alt={product.name} className="w-full h-20 object-cover" />
            </div>
          </div>

          {/* Product Details */}
          <div>
            <h1 className="text-4xl font-bold mb-4">{product.name}</h1>
            <p className="text-xl font-semibold mb-4">Price: {product.price}</p>
            <p className="text-gray-700 mb-4">Availability: In Stock</p>
            <p className="mb-4">
              <strong>Description:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam scelerisque ligula sed felis sodales, sed auctor purus vulputate. Duis vulputate
              lectus ut dapibus malesuada.
            </p>
            <p className="mb-4">
              <strong>Material:</strong> 100% Cotton
            </p>
            <p className="mb-4">
              <strong>Sizes:</strong> S, M, L, XL, XXL
            </p>

            {/* Add to Cart / Buy Now Buttons */}
            <div className="flex space-x-4">
              <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded">
                Add to Cart
              </button>
              <button className="bg-green-500 text-white font-bold py-2 px-4 rounded">
                Buy Now
              </button>
            </div>

            {/* Share Product */}
            <div className="mt-6">
              <p className="font-bold mb-2">Share:</p>
              <div className="flex space-x-4">
                <button className="bg-gray-200 p-2 rounded-full">Facebook</button>
                <button className="bg-gray-200 p-2 rounded-full">Twitter</button>
                <button className="bg-gray-200 p-2 rounded-full">Instagram</button>
              </div>
            </div>
          </div>
        </div>

        {/* Shipping Info */}
        <div className="mt-8 p-4 bg-gray-100 rounded-lg">
          <h3 className="text-2xl font-bold mb-2">Shipping Information</h3>
          <p>Delivery time: 2-5 business days</p>
          <p>Free shipping on orders over €50.</p>
          <p>Return within 30 days for a full refund.</p>
        </div>

        {/* Customer Reviews */}
        <div className="mt-8">
          <h3 className="text-2xl font-bold mb-4">Customer Reviews</h3>
          <div className="space-y-4">
            <div className="p-4 bg-gray-100 rounded-lg">
              <p><strong>John Doe</strong></p>
              <p>⭐⭐⭐⭐⭐</p>
              <p>Great product! Fits perfectly and love the design.</p>
            </div>
            <div className="p-4 bg-gray-100 rounded-lg">
              <p><strong>Jane Smith</strong></p>
              <p>⭐⭐⭐⭐</p>
              <p>Good quality but delivery took a bit longer than expected.</p>
            </div>
          </div>
        </div>

        {/* Related Products */}
        <div className="mt-8">
          <h3 className="text-2xl font-bold mb-4">Related Products</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {relatedProducts.map((relatedProduct) => (
              <ProductCard key={relatedProduct.id} product={relatedProduct} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductPage;
