import React from "react";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const CheckoutPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50 p-6 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6">Kassa</h1>
        <form className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
          <div className="mb-4">
            <label className="block text-gray-700">Naam</label>
            <input type="text" className="border border-gray-300 p-2 rounded w-full" required />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">E-mail</label>
            <input type="email" className="border border-gray-300 p-2 rounded w-full" required />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Adres</label>
            <input type="text" className="border border-gray-300 p-2 rounded w-full" required />
          </div>
          <button type="submit" className="bg-gradient-to-r from-pink-400 via-purple-400 to-blue-400 text-white font-bold py-2 px-4 rounded w-full">
            Bestelling Plaatsen
          </button>
        </form>
      </main>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
