import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa"; // Import icons
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle contact form submission logic here (e.g., API call)
    console.log("Contact form submitted: ", formData);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <main className="flex-grow p-6 md:p-10 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6 text-gray-800">Neem Contact Met Ons Op</h1>
        
        <div className="flex flex-col md:flex-row justify-between items-start">
          <section className="md:w-1/2 mb-8 p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">Stuur Ons Een Bericht</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="name">
                  Naam
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 p-2 rounded w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="email">
                  E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 p-2 rounded w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="message">
                  Bericht
                </label>
                <textarea
                  name="message"
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 p-2 rounded w-full"
                  rows="4"
                  placeholder="Je bericht hier..."
                />
              </div>
              <button
                type="submit"
                className="bg-gradient-to-r from-pink-400 via-purple-400 to-blue-400 text-white font-bold py-3 px-8 rounded-full shadow-lg w-full"
              >
                Verzenden
              </button>
            </form>
          </section>

          <section className="md:w-1/2 mb-8 md:ml-4 p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">Volg Ons Op Sociale Media</h2>
            <div className="flex justify-center items-center space-x-4 mb-4">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-600 hover:underline">
                <FaFacebook className="text-3xl" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="flex items-center text-pink-600 hover:underline">
                <FaInstagram className="text-3xl" />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:underline">
                <FaTwitter className="text-3xl" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-700 hover:underline">
                <FaLinkedin className="text-3xl" />
              </a>
            </div>
            <p className="text-gray-700 mb-2">Volg ons voor het laatste nieuws, aanbiedingen en updates! We houden je op de hoogte van de nieuwste productlanceringen, exclusieve kortingen en tips om het meeste uit je abonnement te halen.
            </p>
            <p className="text-gray-700 mb-2">
                Onze sociale media kanalen zijn ook een geweldige plek om betrokken te raken bij onze community. Deel je ervaringen, stel vragen en krijg inspiratie van andere klanten die onze producten gebruiken.
            </p>
            <p className="text-gray-700">
                We kijken ernaar uit om met je in contact te komen en je te betrekken bij ons merk. Jouw feedback is belangrijk voor ons, en we willen dat je je deel voelt van onze groeiende familie!
            </p>
          </section>
        </div>

        <section className="mb-8 p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">24/7 WhatsApp Ondersteuning</h2>
          <p className="text-gray-700">
            Heb je vragen of heb je hulp nodig? Neem contact met ons op via WhatsApp! We zijn 24/7 beschikbaar om je te helpen. 
            <br />
            <strong>WhatsApp Nummer: +31 6 12345678</strong>
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
