import React, { useEffect, useState } from 'react';
import { FaShoppingCart, FaGift, FaHeart } from 'react-icons/fa'; // Importing some example icons

const steps = [
    {
        number: '1',
        title: 'Kies je favoriete shorts',
        description: 'Browse through our exclusive collection and pick your favorites.',
        icon: <FaShoppingCart size={40} className="text-pink-400" />,
    },
    {
        number: '2',
        title: 'Abonneer je',
        description: 'Choose a subscription plan that fits your style and budget.',
        icon: <FaGift size={40} className="text-purple-400" />,
    },
    {
        number: '3',
        title: 'Geniet van je shorts',
        description: 'Receive your selected shorts at your doorstep every month.',
        icon: <FaHeart size={40} className="text-blue-400" />,
    },
];

const StepsSection = () => {
    const [isVisibleTitle, setIsVisibleTitle] = useState(false);
    const [isVisibleSteps, setIsVisibleSteps] = useState(Array(steps.length).fill(false));

    useEffect(() => {
        // First, show the title
        const titleTimeout = setTimeout(() => {
            setIsVisibleTitle(true);
        }, 500); // Delay for the title to appear

        // Then, set timeouts to control the visibility of each step
        const timeouts = steps.map((_, index) => 
            setTimeout(() => {
                setIsVisibleSteps(prev => {
                    const newVisibility = [...prev];
                    newVisibility[index] = true; // Make the step visible
                    return newVisibility;
                });
            }, 1500 + index * 400) // Stagger the animations for steps
        );

        return () => {
            clearTimeout(titleTimeout); // Cleanup title timeout on unmount
            timeouts.forEach(timeout => clearTimeout(timeout)); // Cleanup step timeouts on unmount
        };
    }, []);

    return (
        <section className="bg-white">
            <div className="container mx-auto px-4">
                {/* Title with animation */}
                <h2 className={`text-3xl sm:text-5xl mt-12 sm:mt-24  text-gray-800 font-bold text-center mb-8 transition-opacity duration-1000 ease-in-out ${isVisibleTitle ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                    Hoe werkt het?
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {steps.map((step, index) => (
                        <div 
                            key={step.number} 
                            className={`flex flex-col items-center bg-white p-6 rounded-lg shadow-lg transition-opacity duration-1000 ease-in-out ${isVisibleSteps[index] ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
                        >
                            <div className="mb-4">{step.icon}</div>
                            <h3 className="text-2xl font-semibold mb-2">{step.number}. {step.title}</h3>
                            <p className="text-gray-700 text-center">{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default StepsSection;
