import React from "react";
import { FaPeopleCarry, FaLightbulb, FaLeaf, FaEnvelope } from "react-icons/fa";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-100 via-slate-100 to-white">
      <Navbar />
      <main className="flex-grow p-6 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6">Over Ons</h1>

        <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-center">Onze Missie</h2>
          <p className="text-gray-700 text-center">
            Bij CityShorts is het onze missie om unieke, comfortabele en stijlvol ontworpen ondergoed aan te bieden dat de karakteristieke elementen van steden in Nederland weerspiegelt. We streven ernaar om elke klant een beetje blijdschap en trots te brengen door middel van onze producten.
          </p>
        </section>

        <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-center">Onze Visie</h2>
          <p className="text-gray-700 text-center">
            Onze visie is om een toonaangevende naam te worden in de mode-industrie door het aanbieden van innovatieve en duurzame producten. We geloven dat stijl en comfort hand in hand gaan en dat iedereen het recht heeft om zich goed te voelen in zijn of haar kleding.
          </p>
        </section>

        {/* Grid layout for medium and larger screens */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <section className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4 text-center">Ons Team</h2>
            <p className="text-gray-700 text-center">
              Ons team bestaat uit gepassioneerde individuen met een liefde voor mode en design. We werken hard om ervoor te zorgen dat elk stuk ondergoed dat we aanbieden van de hoogste kwaliteit is en voldoet aan de verwachtingen van onze klanten.
            </p>
          </section>

          <section className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4 text-center">Onze Waarden</h2>
            <ul className="list-disc list-inside text-gray-700 text-center">
              <li><FaLightbulb className="inline mr-2" /> Innovatie</li>
              <li><FaLeaf className="inline mr-2" /> Duurzaamheid</li>
              <li><FaPeopleCarry className="inline mr-2" /> Klanttevredenheid</li>
              <li><FaEnvelope className="inline mr-2" /> Gemeenschapsbetrokkenheid</li>
            </ul>
          </section>
        </div>

        <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-center">Neem Contact Met Ons Op</h2>
          <p className="text-gray-700 text-center">
            Heb je vragen of opmerkingen? Aarzel niet om ons te contacteren via onze <a href="/contact" className="text-blue-500 hover:underline">contactpagina</a>. We horen graag van je!
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutUs;
