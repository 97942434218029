import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";


const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    subscription: "Basis Abonnement",
    specialInstructions: "",
  });

  const [currentStep, setCurrentStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const handleNext = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle signup logic here (e.g., API call)
    console.log("User data submitted: ", formData);
  };

  return (
    <div className="flex flex-col min-h-screen mt-0">
      <Navbar />
      <main className="flex-grow bg-gradient-to-b from-blue-100 via-slate-100 to-white p-6 flex flex-col md:flex-row justify-center items-center">
        <div className="md:w-1/2 max-w-md mx-auto mt-20 bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold text-center mb-6">Aanmelden</h1>
          <form onSubmit={currentStep === 3 ? handleSubmit : handleNext}>
            {currentStep === 1 && (
              <div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="name">
                    Naam
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="password">
                    Wachtwoord
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="phone">
                    Telefoonnummer
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="address">
                    Adres
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                    className="border border-gray-300 p-2 rounded w-full"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="subscription">
                    Kies je Abonnementsvorm
                  </label>
                  <select
                    name="subscription"
                    id="subscription"
                    value={formData.subscription}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded w-full"
                  >
                    <option value="Basis Abonnement">Basis Abonnement</option>
                    <option value="Basis+ Abonnement">Basis+ Abonnement</option>
                    <option value="Mix & Match Abonnement">Mix & Match Abonnement</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700" htmlFor="specialInstructions">
                    Speciale instructies voor je bestelling
                  </label>
                  <textarea
                    name="specialInstructions"
                    id="specialInstructions"
                    value={formData.specialInstructions}
                    onChange={handleChange}
                    className="border border-gray-300 p-2 rounded w-full"
                    rows="3"
                    placeholder="Bijv. allergieën, voorkeuren..."
                  />
                </div>
              </div>
            )}
            <div className="flex justify-between">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="bg-gray-300 font-bold py-2 px-4 rounded"
                >
                  Terug
                </button>
              )}
              <button
                type="submit"
                className="bg-sky-300 font-bold py-3 px-8 rounded-full shadow-lg transform transition hover:scale-105 hover:shadow-2xl"
              >
                {currentStep === 3 ? "Aanmelden" : "Volgende"}
              </button>
            </div>
            <p className="text-center mt-4">
              Heb je al een account?{" "}
              <Link to="/login" className="text-blue-500 hover:underline">
                Inloggen
              </Link>
            </p>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SignUp;
