import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CallToAction from "./CallToAction";

const SubscriptionOptions = () => {
    const [isVisibleTitle, setIsVisibleTitle] = useState(false);
    const [isVisibleFirstCard, setIsVisibleFirstCard] = useState(false);
    const [isVisibleSecondCard, setIsVisibleSecondCard] = useState(false);
    const [isVisibleThirdCard, setIsVisibleThirdCard] = useState(false);

    useEffect(() => {
        const timeoutIdTitle = setTimeout(() => {
            setIsVisibleTitle(true);
        }, 4000); // Delay for the title

        const timeoutIdFirstCard = setTimeout(() => {
            setIsVisibleFirstCard(true);
        }, 5000); // Delay for the first card

        const timeoutIdSecondCard = setTimeout(() => {
            setIsVisibleSecondCard(true);
        }, 5500); // Delay for the second card

        const timeoutIdThirdCard = setTimeout(() => {
            setIsVisibleThirdCard(true);
        }, 6000); // Delay for the third card

        

        return () => {
            clearTimeout(timeoutIdTitle);
            clearTimeout(timeoutIdFirstCard);
            clearTimeout(timeoutIdSecondCard);
            clearTimeout(timeoutIdThirdCard); // Cleanup timeouts on unmount
            
        };
    }, []);

    return (
        <section className="py-10">
  <div className="container mx-auto px-4">
    {/* Animated Title */}
    <div className={`transition-opacity duration-1000 ease-in-out ${isVisibleTitle ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
      <h2 className="text-3xl font-bold text-center mb-6">Onze Abonnementsopties</h2>
    </div>
    <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-6">
      {/* Basis Abonnement */}
      <div className={`bg-white p-6 rounded-lg shadow-lg flex flex-col flex-grow transform transition-transform duration-300 hover:scale-105 ease-in-out ${isVisibleFirstCard ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        <h3 className="text-2xl font-bold text-center mb-4">Basis</h3>
        <p className="text-lg text-gray-700 mb-4">
          Ontvang elke maand een nieuwe set onderbroeken in verschillende stijlen en kleuren.
        </p>
        <p className="text-xl font-bold text-center text-blue-600 mb-4">€15 / maand</p>
      </div>

      {/* Basis+ Abonnement */}
      <div className={`bg-white p-6 rounded-lg shadow-lg flex flex-col flex-grow transform transition-transform duration-300 hover:scale-105 ease-in-out ${isVisibleSecondCard ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        <h3 className="text-2xl font-bold text-center mb-4">Basis+</h3>
        <p className="text-lg text-gray-700 mb-4">
          Geniet van een hogere frequentie met elke twee weken een nieuwe set onderbroeken.
        </p>
        <p className="text-xl font-bold text-center text-blue-600 mb-4">€25 / maand</p>
      </div>

      {/* Mix & Match Abonnement */}
      <div className={`bg-white p-6 rounded-lg shadow-lg flex flex-col flex-grow transform transition-transform duration-300 hover:scale-105 ease-in-out ${isVisibleThirdCard ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        <h3 className="text-2xl font-bold text-center mb-4">Mix & Match</h3>
        <p className="text-lg text-gray-700 mb-4">
          Kies uit een mix van onderbroeken, sokken en andere lingerie-items in één pakket.
        </p>
        <p className="text-xl font-bold text-center text-blue-600 mb-4">€30 / maand</p>
      </div>
    </div>

    {/* Enkele meld je aan knop onder de abonnementsopties */}
    <div className="mt-20">
        <CallToAction/>
    </div>
    
  </div>
</section>

    );
};

export default SubscriptionOptions;
