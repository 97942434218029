import React from "react";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const FAQ = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50 p-6 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6">Veelgestelde Vragen</h1>
        <section className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2">Hoe kan ik een bestelling plaatsen?</h2>
          <p className="text-gray-700 mb-4">Je kunt een bestelling plaatsen door een product te selecteren en deze aan je winkelwagentje toe te voegen.</p>

          <h2 className="text-2xl font-semibold mb-2">Wat is het retourbeleid?</h2>
          <p className="text-gray-700 mb-4">Je kunt je producten binnen 30 dagen retourneren als ze in onbeschadigde staat zijn.</p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default FAQ;
