import React, { useState } from "react";

const FilterSidebar = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false); // State to toggle visibility
  const [selectedCities, setSelectedCities] = useState([]);
  const cities = ["New York", "Tokyo", "Paris", "London"]; // Add more cities as needed

  const toggleCitySelection = (city) => {
    setSelectedCities((prev) =>
      prev.includes(city) ? prev.filter((c) => c !== city) : [...prev, city]
    );
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible); // Toggle filter visibility
  };

  return (
    <div className="w-full lg:w-60">
      {/* Button to show/hide filter */}
      <button
        onClick={toggleFilterVisibility}
        className="bg-sky-300 hover:bg-sky-400 text-black py-2 px-4 rounded-lg font-semibold transition w-full mb-4"
      >
        {isFilterVisible ? "Hide Filters" : "Show Filters"}
      </button>

      {/* Filter sidebar, conditionally rendered based on the state */}
      {isFilterVisible && (
        <aside className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="font-bold text-2xl mb-6 text-gray-700">Filter by</h3>


          {/* Cities Filter */}
          <div className="mb-6">
            <label className="block font-semibold text-gray-700 mb-3">Cities</label>
            <div className="space-y-2">
              {cities.map((city) => (
                <label
                  key={city}
                  className={`block p-2 rounded-lg cursor-pointer transition ${selectedCities.includes(city) ? "bg-blue-100" : "bg-gray-100"} hover:bg-blue-200`}
                  onClick={() => toggleCitySelection(city)}
                >
                  <input
                    type="checkbox"
                    checked={selectedCities.includes(city)}
                    onChange={() => toggleCitySelection(city)}
                    className="form-checkbox h-5 w-5 text-blue-600 mr-2"
                  />
                  {city}
                </label>
              ))}
            </div>
          </div>

          {/* Color Filter (Example for visual appeal) */}
          <div className="mb-6">
            <label className="block font-semibold text-gray-700 mb-3">Colors</label>
            <div className="flex gap-4">
              {["red", "blue", "green", "yellow"].map((color) => (
                <div
                  key={color}
                  className={`h-6 w-6 rounded-full bg-${color}-500 cursor-pointer border-2 border-white hover:shadow-lg`}
                  title={color.charAt(0).toUpperCase() + color.slice(1)}
                />
              ))}
            </div>
          </div>

          <button className="w-full bg-sky-300 hover:bg-sky-400 text-black py-2 px-4 rounded-lg font-semibold transition">
            Apply Filters
          </button>
        </aside>
      )}
    </div>
  );
};

export default FilterSidebar;
