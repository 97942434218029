import React from "react";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const ReturnPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50 p-6 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6">Retourbeleid</h1>
        <section className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <p className="text-gray-700 mb-4">
            Bij CityShorts willen we dat je tevreden bent met je aankoop. Als je niet tevreden bent, kun je je product binnen 30 dagen retourneren.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Voorwaarden voor Retourneren</h2>
          <p className="text-gray-700 mb-4">
            - Producten moeten ongebruikt en in de originele verpakking zijn.<br />
            - Je moet een ontvangstbewijs of bewijs van aankoop kunnen tonen.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ReturnPolicy;
