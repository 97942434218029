import React, { useState, useEffect } from "react";
import Navbar from "../components/frontpage/Navbar";
import FeaturedBanner from "../components/shop/FeaturedBanner";
import FilterSidebar from "../components/shop/FilterSidebar";
import SortOptions from "../components/shop/SortOptions";
import ProductGrid from "../components/shop/ProductGrid";
import PromotionsSection from "../components/shop/PromotionsSection";
import Footer from "../components/frontpage/Footer";

const Shop = () => {
  const [products, setProducts] = useState([]);

  const themes = [
    "Vibrant & Lively",
    "Modern & Chic",
    "Classic & Timeless",
    "Artistic & Creative",
    "Cozy & Comfortable",
    "Urban & Bold",
    "Trendy & Fashionable",
    "Elegant & Sophisticated",
];

const placeholderItems = [
    { id: 1, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 2, name: "Rotterdam", price: "€17.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 3, name: "Utrecht", price: "€15.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 4, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 5, name: "Rotterdam", price: "€17.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 6, name: "Utrecht", price: "€15.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 7, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 8, name: "Rotterdam", price: "€17.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    { id: 9, name: "Utrecht", price: "€15.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
];

  useEffect(() => {
    setProducts(placeholderItems);
  }, []);

  return (
    <div className=" overflow-hidden">
      <div className=" bg-gradient-to-b from-blue-100 via-slate-100 to-white">
        <Navbar />
        <FeaturedBanner />
        </div>
      <div className="container mx-auto flex flex-wrap mt-24">
        <div className="w-full lg:w-1/4 p-4">
          <FilterSidebar />
        </div>
        <div className="w-full lg:w-3/4 p-4">
          <SortOptions />
          <ProductGrid products={products} />
        </div>
      </div>
      <PromotionsSection />
      <Footer />
    </div>
  );
};

export default Shop;
