import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const OptionsSection = () => {
    const [isVisibleFirstCard, setIsVisibleFirstCard] = useState(false);
    const [isVisibleSecondCard, setIsVisibleSecondCard] = useState(false);
    const [isVisibleText, setIsVisibleText] = useState(false); // New state for text visibility

    useEffect(() => {
        const timeoutId1 = setTimeout(() => {
            setIsVisibleFirstCard(true);
        }, 1500); // Delay for first card

        const timeoutId2 = setTimeout(() => {
            setIsVisibleSecondCard(true);
        }, 2000); // Delay for second card (longer for staggered effect)

        const timeoutIdText = setTimeout(() => {
            setIsVisibleText(true);
        }, 100); // Delay for the text

        return () => {
            clearTimeout(timeoutId1);
            clearTimeout(timeoutId2);
            clearTimeout(timeoutIdText); // Cleanup timeouts on unmount
        };
    }, []);

    return (
        <section className="bg-white py-0 mb-10">
            <div className="container mx-auto px-4">
                {/* Animated Text */}
                <div className={`transition-opacity duration-1000 ease-in-out ${isVisibleText ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                    <h2 className="text-4xl font-bold text-center mb-8">Wat past het best bij je?</h2>
                </div>
                <div className="grid md:grid-cols-2 gap-8">
                    {/* First Card */}
                    <div className={`flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow transition-opacity duration-1000 ease-in-out ${isVisibleFirstCard ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                        <h3 className="text-xl font-semibold mb-4">Subscription-Based</h3>
                        <p className="text-gray-700 mb-4">
                            Sign up for our subscription service to receive exclusive monthly deliveries of our latest designs.
                        </p>
                        <Link to="/subscription">
                            <button className="bg-sky-200 text-black font-semibold py-2 px-3 rounded-lg transform transition hover:scale-105 hover:shadow-2xl">
                                Learn More
                            </button>
                        </Link>
                    </div>

                    {/* Second Card */}
                    <div className={`flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow transition-opacity duration-1000 ease-in-out ${isVisibleSecondCard ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                        <h3 className="text-xl font-semibold mb-4">Buy Individual Shorts</h3>
                        <p className="text-gray-700 mb-4">
                            Prefer to choose your favorites? Browse our collection and purchase individual shorts anytime.
                        </p>
                        <Link to="/shop">
                            <button className="bg-sky-200 text-black font-semibold py-2 px-3 rounded-lg transform transition hover:scale-105 hover:shadow-2xl">
                                Shop now
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OptionsSection;
