import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FrontPage from "./pages/Frontpage";
import Shop from "./pages/Shop";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import AboutUs from "./pages/AboutUs";
import Subscription from "./pages/Subscription";
import Contact from "./pages/Contact";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import FAQ from "./pages/FAQ";
import ReturnPolicy from "./pages/ReturnPolicy";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const placeholderItems = [
    { id: 1, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150" },
    { id: 2, name: "Rotterdam", price: "€17.99", image: "https://via.placeholder.com/150" },
    { id: 3, name: "Utrecht", price: "€15.99", image: "https://via.placeholder.com/150"},
    { id: 4, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150" },
    { id: 5, name: "Rotterdam", price: "€17.99", image: "https://via.placeholder.com/150" },
    { id: 6, name: "Utrecht", price: "€15.99", image: "https://via.placeholder.com/150"},
    { id: 7, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150"},
    { id: 8, name: "Rotterdam", price: "€17.99", image: "https://via.placeholder.com/150" },
    { id: 9, name: "Utrecht", price: "€15.99", image: "https://via.placeholder.com/150"
  }
    // more products...
  ];

  useEffect(() => {
    setProducts(placeholderItems);
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          {/* Pass the products to ProductPage via route */}
          <Route path="/product/:id" element={<ProductPage products={products} />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
