// PromotionsSection.js
import React from "react";

const PromotionsSection = () => {
  return (
    <section className="bg-slate-100 p-6 text-center my-12">
      <h2 className="text-3xl font-bold mb-4">Special Offer</h2>
      <p className="text-lg">Buy 2 CityShorts, Get 1 Free! Limited Time Offer.</p>
      <button className="bg-green-500 text-white px-6 py-2 rounded-full mt-4 hover:bg-green-700 transition">Shop Now</button>
    </section>
  );
};

export default PromotionsSection;
