import React from "react";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const CartPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50 p-6 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6">Winkelwagentje</h1>
        <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          {/* Voorbeeld van een product in het winkelwagentje */}
          <div className="flex justify-between mb-4">
            <p>Productnaam</p>
            <p>€XX,XX</p>
          </div>
          <div className="flex justify-between mb-4">
            <p>Totaal:</p>
            <p>€XX,XX</p>
          </div>
          <button className="bg-gradient-to-r from-pink-400 via-purple-400 to-blue-400 text-white font-bold py-2 px-4 rounded w-full">
            Doorgaan naar Kassa
          </button>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CartPage;
