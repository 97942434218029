// FeaturedBanner.js
import React from "react";

const FeaturedBanner = () => {
  return (
    <section className="w-full h-80  bg-cover bg-center text-black flex items-center justify-center" 
      style={{ backgroundImage: "url('your-image-url')" }}>
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4 mt-20">Exclusive Collection</h1>
        <button className="bg-sky-200 text-black font-semibold py-2 px-3 rounded-md transform transition hover:scale-105 hover:shadow-2xl">Shop Now</button>
      </div>
    </section>
  );
};

export default FeaturedBanner;
