import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
    return (
        <section className="relative bg-gradient-to-b from-blue-100 via-slate-100 to-white h-[60vh] flex flex-col justify-center items-center text-center w-full overflow-hidden mt-auto">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-800 mb-4"> {/* Adjusted font size for smaller screens */}
                Draag je Stad met Trots!
            </h1>
            <p className="text-lg sm:text-xl text-gray-600 mb-8 max-w-xl mx-auto"> {/* Adjusted text size for smaller screens */}
                Ontdek unieke onderbroeken geïnspireerd door de karakteristieke charme van de mooiste steden in Nederland!
            </p>
            <Link to="/shop">
                <button className="bg-sky-200 text-black font-semibold py-3 px-4 rounded-xl transform transition hover:scale-105 hover:shadow-2xl">
                    Shop the Collection
                </button>
            </Link>
        </section>
    );
};

export default Hero;
