const CityCard = ({ name, theme, image, onClick }) => {
    const cardStyle = {
        height: '300px', 
        width: '300px', 
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        marginTop: '5px',
        marginBottom: '25px',
    };

    const imageStyle = {
        width: '100%',
        height: '60%',
        objectFit: 'cover',
    };

    // Add media query in JavaScript for smaller screen sizes
    const smallScreenStyle = window.matchMedia('(max-width: 640px)').matches
        ? { height: '200px', width: '200px' }
        : {};

    return (
        <div
            onClick={onClick}
            className="carousel-card city-card cursor-pointer city"
            style={{ ...cardStyle, ...smallScreenStyle }} // Merge styles
        >
            <img 
                src={image} 
                alt={name} 
                style={imageStyle} 
            />
            <div className="p-2 mt-5" style={{ height: '40%' }}>
                <h3 className="text-lg font-medium">{name}</h3>
                <p className="text-sm text-gray-600">{theme}</p>
            </div>
        </div>
    );
};

export default CityCard;
