import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";
import "./Login.css"; // Import your custom CSS file for background styles

const Login = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here (e.g., API call)
    console.log("User credentials submitted: ", credentials);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-100 via-slate-100 to-white bg-pattern">
      <Navbar />
      <main className="flex-grow flex flex-col md:flex-row items-center justify-center p-6">
        <div className="flex flex-col sm: mt-10 md:w-1/2 max-w-sm mx-auto p-4">
          <h1 className="text-4xl font-bold text-center mb-6">Inloggen</h1>
          <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <label className="block text-gray-700" htmlFor="email">
                E-mail
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={credentials.email}
                onChange={handleChange}
                required
                className="border border-gray-300 p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700" htmlFor="password">
                Wachtwoord
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={credentials.password}
                onChange={handleChange}
                required
                className="border border-gray-300 p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-sky-300 text-white font-bold py-2 px-8 rounded-full shadow-lg transform transition hover:scale-105 hover:shadow-2xl"
              >
                Inloggen
              </button>
            </div>
            <p className="text-center mt-4">
              Heb je nog geen account?{" "}
              <Link to="/signup" className="text-blue-500 hover:underline">
                Aanmelden
              </Link>
            </p>
          </form>
        </div>
        <div className="hidden md:flex md:w-1/2 justify-center items-center p-6">
          <img
            src="https://via.placeholder.com/400"
            alt="Placeholder"
            className="rounded-lg shadow-lg"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Login;
