import { Link } from 'react-router-dom';

const CallToAction = () => {
  return (
    <div className="text-center mb-6">
      <h2 className="text-xl font-bold">Ready?</h2>
      <div className="mt-5">
        <Link to="/signup"> {/* Change the path to your actual sign-up route */}
          <button className="bg-blue-600 text-white font-bold py-3 px-6 rounded-full shadow-md transform transition duration-300 hover:scale-105">
            Meld je nu aan!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CallToAction;
