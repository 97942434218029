import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";
import StepsSection from "../components/subscription/StepsSection"; // Import the StepsSection component
import SubscriptionOptions from "../components/subscription/SubscriptionOptions";

const Subscription = () => {
  const [isVisibleTitle, setIsVisibleTitle] = useState(false);
  const [isVisibleDescription, setIsVisibleDescription] = useState(false);
  const [isVisibleBlock, setIsVisibleBlock] = useState(false); // State for the entire block

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // Show the title after 3000ms
    const titleTimeout = setTimeout(() => {
      setIsVisibleTitle(true);
    }, 3000); // Delay for the title to appear

    // Show the description after 3500ms
    const descriptionTimeout = setTimeout(() => {
      setIsVisibleDescription(true);
    }, 3500); // Delay for the description to appear

    // Show the entire block after 4000ms
    const blockTimeout = setTimeout(() => {
      setIsVisibleBlock(true);
    }, 4000); // Delay for the entire block to appear

    return () => {
      clearTimeout(titleTimeout); // Cleanup title timeout on unmount
      clearTimeout(descriptionTimeout); // Cleanup description timeout on unmount
      clearTimeout(blockTimeout); // Cleanup block timeout on unmount
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
  <Navbar />
  <main className="flex-grow bg-white">
    <section className="mt-0 relative bg-gradient-to-b from-blue-100 via-slate-100 to-white h-[40vh] py-20 text-center px-4 sm:px-8">
      <h1 className="text-3xl sm:text-5xl mt-12 sm:mt-24 font-extrabold text-gray-800 mb-4">
        Onze Abonnementsdienst
      </h1>
      <p className="text-lg sm:text-xl text-gray-600 mb-8 max-w-lg sm:max-w-2xl mx-auto">
        Ontdek onze exclusieve abonnementsdienst voor unieke onderbroeken die elke maand bij jou thuis worden bezorgd.
      </p>
      <Link to="/signup">
        <button className="bg-gradient-to-r from-pink-400 via-purple-400 to-blue-400 text-white font-bold py-2 sm:py-3 px-6 sm:px-8 rounded-full shadow-lg transform transition hover:scale-105 hover:shadow-2xl">
          Meld je aan
        </button>
      </Link>
    </section>

        {/* Add margin-top for mobile screens */}
        <div className="mt-24 sm:mt-20">
          <StepsSection />
        </div>

        {/* Title with animation for the "Waarom Kiezen voor een Abonnement?" section */}
        <section className="py-8 sm:py-10 bg-white">
          <div className={`container mx-auto px-4 sm:px-6 md:px-8 transition-opacity duration-1000 ease-in-out ${isVisibleBlock ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
            <h2 className={`text-3xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-6 text-black transition-opacity duration-1000 ease-in-out ${isVisibleTitle ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
              Waarom Kiezen voor een Abonnement?
            </h2>
            {isVisibleDescription && (
              <p className={`text-base sm:text-lg text-gray-800 mb-4 sm:mb-6 text-center transition-opacity duration-1000 ease-in-out ${isVisibleDescription ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                Met ons abonnement krijg je niet alleen toegang tot onze nieuwste ontwerpen, maar ook:
              </p>
            )}
            <ul className="list-disc list-inside text-base sm:text-lg text-gray-700 max-w-lg sm:max-w-2xl mx-auto space-y-3">
              {["Exclusieve kortingen op toekomstige aankopen.", "Maandelijkse levering van unieke en trendy onderbroeken.", "De mogelijkheid om je voorkeuren aan te passen naar jouw smaak."].map((item, index) => (
                <li
                  key={index}
                  className="bg-white p-2 sm:p-4 rounded-lg shadow-md transition-shadow hover:shadow-lg"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </section>

        <SubscriptionOptions />
        
      </main>
      <Footer />
    </div>
  );
};

export default Subscription;
