import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom for navigation
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa"; // Import icons from react-icons

const Footer = () => {
  return (
    <footer className="bg-gray-200 text-black p-6">
      <div className="container mx-auto flex flex-wrap justify-between">
        <div className="w-1/2 md:w-1/4">
          <h3 className="font-bold text-lg">CityShorts</h3>
          <ul className="mt-2">
            <li>
              <Link to="/faq" className="hover:underline">
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/shipping" className="hover:underline">
                Shipping
              </Link>
            </li>
            <li>
              <Link to="/returns" className="hover:underline">
                Returns
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:underline">
                About Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-1/2 md:w-1/4">
          <h3 className="font-bold text-lg">Customer Service</h3>
          <ul className="mt-2">
            <li>
              <Link to="/contact" className="hover:underline">
                Contact
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="hover:underline">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms-and-conditions" className="hover:underline">
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link to="/product" className="hover:underline">
                Products
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 mt-4 md:mt-0">
          <h3 className="font-bold text-lg text-center">Follow Us</h3>
          <ul className="mt-2 flex justify-center space-x-24">
            <li>
              <Link to="/social/facebook" className="hover:underline">
                <FaFacebook size={20} />
              </Link>
            </li>
            <li>
              <Link to="/social/instagram" className="hover:underline">
                <FaInstagram size={20} />
              </Link>
            </li>
            <li>
              <Link to="/social/twitter" className="hover:underline">
                <FaTwitter size={20} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
