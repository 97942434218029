import React from "react";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50 p-6 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6">Algemene Voorwaarden</h1>
        <section className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2">Algemene Voorwaarden</h2>
          <p className="text-gray-700 mb-4">
            Deze algemene voorwaarden zijn van toepassing op alle bestellingen die je plaatst via onze website.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Bestellingen</h2>
          <p className="text-gray-700 mb-4">
            Door een bestelling te plaatsen, ga je akkoord met onze voorwaarden.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
