import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <Link to={`/product/${product.id}`}>
        <img src={product.image} alt={product.name} className="w-full h-40 object-cover mb-4" />
        <h2 className="text-lg font-bold mb-2">{product.name}</h2>
        <p className="text-gray-700 mb-2">Price: {product.price}</p>
        
      </Link>
    </div>
  );
};

export default ProductCard;
