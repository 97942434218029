// FrontPage.js
import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";
import FrontPageCarousel from "../components/frontpage/FrontPageCarousel";
import OptionsSection from "../components/frontpage/OptionsSection"; 
import { Link } from "react-router-dom";
import Hero from "../components/frontpage/Hero";

const FrontPage = () => {
    const signUpRef = useRef(null);
    const [scrolled, setScrolled] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const themes = [
        "Vibrant & Lively",
        "Modern & Chic",
        "Classic & Timeless",
        "Artistic & Creative",
        "Cozy & Comfortable",
        "Urban & Bold",
        "Trendy & Fashionable",
        "Elegant & Sophisticated",
    ];
    
    const cityData = [
        { id: 1, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
        { id: 2, name: "Rotterdam", price: "€17.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
        { id: 3, name: "Utrecht", price: "€15.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
        { id: 4, name: "Amsterdam", price: "€19.99", image: "https://via.placeholder.com/150", theme: themes[Math.floor(Math.random() * themes.length)] },
    ];

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (signUpRef.current) {
            observer.observe(signUpRef.current);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            observer.disconnect();
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleCardClick = (product) => {
        setSelectedProduct(product);
    };

    return (
        <div className=" overflow-hidden flex flex-col min-h-screen">
            
                <Navbar scrolled={scrolled} />
                <Hero />
            
            <main className="flex-grow">
                <OptionsSection />

                <FrontPageCarousel cityData={cityData} handleCardClick={handleCardClick} />

                <section className="flex justify-center py-8">
                    <Link to="/shop" className="bg-sky-200 text-black font-semibold py-2 px-3 rounded-lg transform transition hover:scale-105 hover:shadow-2xl">
                        Explore More
                    </Link>
                </section>

                <section
                    ref={signUpRef}
                    className={`bg-white p-6 flex flex-col items-center mb-20 transform transition-transform duration-500 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}
                >
                    <h2 className="text-2xl font-bold mb-4">Stay in the loop!</h2>
                    <p className="text-lg mb-4">Get 10% off your first order when you sign up.</p>
                    <div className="flex">
                        <input type="email" placeholder="Enter your email" className="border border-gray-300 rounded-l px-4 py-2" />
                        <button className="bg-sky-200 text-black font-semibold py-2 px-3 rounded-r-md transform transition hover:scale-105 hover:shadow-2xl">
                            Sign Up
                        </button>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default FrontPage;
