import React from "react";
import Footer from "../components/frontpage/Footer";
import Navbar from "../components/frontpage/Navbar";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow bg-gray-50 p-6 mt-20">
        <h1 className="text-4xl font-bold text-center mb-6">Privacybeleid</h1>
        <section className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <p className="text-gray-700 mb-4">
            Dit is het privacybeleid van CityShorts. We respecteren je privacy en zorgen ervoor dat je persoonlijke gegevens veilig zijn.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Gegevensverzameling</h2>
          <p className="text-gray-700 mb-4">
            We verzamelen persoonlijke gegevens wanneer je je aanmeldt voor onze nieuwsbrief, een bestelling plaatst of contact met ons opneemt.
          </p>
          <h2 className="text-2xl font-semibold mb-2">Gebruik van Gegevens</h2>
          <p className="text-gray-700 mb-4">
            De verzamelde gegevens worden gebruikt om je bestellingen te verwerken en om je op de hoogte te houden van onze producten en aanbiedingen.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
