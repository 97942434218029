import React, { useRef, useEffect } from "react";
import CityCard from "./CityCard";
import { Link } from "react-router-dom"; // Import Link

const FrontPageCarousel = ({ cityData }) => {
    const carouselRef = useRef(null);

    // Handle scroll for larger screens
    useEffect(() => {
        const handleScroll = () => {
            const isMobile = window.innerWidth < 768;
            // Only apply horizontal scrolling for larger screens
            if (!isMobile && carouselRef.current) {
                const scrollSensitivity = 0.2; // Adjust this value if needed
                carouselRef.current.scrollLeft = window.scrollY * scrollSensitivity;
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div>
            {/* Carousel Section */}
            <section id="cities" className="flex relative">
                <div className="flex justify-between w-full flex-wrap">
                    <div
                        ref={carouselRef}
                        className="relative overflow-hidden h-100 w-full"
                        style={{
                            backgroundColor: '#ffffff',
                        }}
                    >
                        <div className="flex whitespace-nowrap" style={{ marginTop: '20px' }}>
                            {cityData.map((city, index) => (
                                <div key={index} className="inline-block mx-4 city-card" data-city-id={city.id}>
                                    {/* Wrap CityCard with Link */}
                                    <Link
                                        to={{
                                            pathname: `/product/${city.id}`, // Dynamic product route
                                        }}
                                        state={{ city }} // Pass city data as state
                                    >
                                        <CityCard
                                            name={city.name}
                                            theme={city.theme}
                                            price={city.price}
                                            image={city.image}
                                        />
                                    </Link>
                                </div>
                            ))}
                            {/* Cloned elements for seamless looping */}
                            {cityData.map((city, index) => (
                                <div key={`clone-${index}`} className="inline-block mx-4 city-card" data-city-id={city.id}>
                                    <Link
                                        to={{
                                            pathname: `/product/${city.id}`, // Dynamic product route
                                        }}
                                        state={{ city }} // Pass city data as state
                                    >
                                        <CityCard
                                            name={city.name}
                                            theme={city.theme}
                                            price={city.price}
                                            image={city.image}
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FrontPageCarousel;
