// SortOptions.js
import React from "react";

const SortOptions = ({ setSortOption }) => {
  return (
    <div className="flex justify-end space-x-4 p-4">
      <select onChange={(e) => setSortOption(e.target.value)} className="border border-gray-300 rounded px-4 py-2">
        <option value="popularity">Popularity</option>
        <option value="price-low-high">Price: Low to High</option>
        <option value="price-high-low">Price: High to Low</option>
        <option value="newest">Newest</option>
      </select>
    </div>
  );
};

export default SortOptions;
